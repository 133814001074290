<template>
     <div class="ks-page-header">
        <section class="ks-title">
            <h3>{{$route.meta.head}}</h3>
            <button class="btn btn-outline-primary ks-light ks-mail-navigation-block-toggle" data-block-toggle=".ks-mail > .ks-navigation">Menu</button>
        </section>
    </div>
    <div class="ks-page-content">
        <div class="ks-page-content-body ks-mail">
            <div class="ks-navigation ks-browse ks-scrollable" data-auto-height>
                <div class="ks-wrapper">
                    <div class="ks-separator">
                        Menu
                    </div>
                    <ul class="ks-menu">
                        <li>
                            <router-link to="/crf" class="ks-menu-item" :class="{'ks-active': $route.name == 'active'}" title="ERM Partners not imported/imported to PMP">
                                <span class="ks-text">CRF Active</span>
                            </router-link>
                            
                        </li>
                        <li>
                            <router-link to="/crf/approval" class="ks-menu-item" :class="{'ks-active': $route.name == 'approval'}" title="Partner/End User/Vendors added in PMP" >
                                <span class="ks-text">CRF For Approval</span>
                            </router-link>
                        </li>
                 
                        <li>
                            <router-link to="/crf/posting" class="ks-menu-item" :class="{'ks-active': $route.name == 'posting'}" title="Partner/End User/Vendors added in PMP" >
                                <span class="ks-text">CRF For Printing / Posted</span>
                            </router-link>
                        </li>
                        <li v-if="hasAccess">
                            <router-link to="/crf/apv" class=" ks-menu-item " :class="{'ks-active': $route.name == 'PADNJOapproval'}" title="Partner/End User/Vendors added in PMP">
                                <span class="ks-text">PA/DN/JO Approval </span>
                            </router-link>
                        </li>
                        <li >
                            <router-link to="/crf/create" class="text-white ks-menu-item  bg-primary " :class="{'ks-active bg-primary': $route.name == 'create'}" title="Partner/End User/Vendors added in PMP">
                                <span class="ks-text">Create CRF</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <router-view v-slot="{ Component }" name="main">
        
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </router-view>
        </div>
    </div>
</template>

<script>

export default {
   data(){
    return{
        me : {}
    }
   },
   computed:{
        hasAccess(){
            if(this.me.user_access == 'PDAPV' || this.me.user_access == 'JOAPV' || this.me.user_access == 'ADM') return true;

            return false;
        }
   },
   methods:{

        async det_me(){
         this.me =   await  this.API.ME();

            // console.log(this.me)
        }

   },

   mounted(){
        this.det_me();
   }
}
</script>

<style scoped>
@import url(/assets/styles/apps/mail.min.css);
.ks-navigation {
    min-width: 200px !important;
    width: 200px !important;
}

</style>